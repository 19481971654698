export enum NotificationStatus {
  "waiting" = "Esperando",
  "sending" = "Enviando",
  "completed" = "Enviado",
  "failed" = "Falha",
}

export type NotificationUsers = {
  id: number
  firstname: string
  lastname: string
  phone?: string
  email?: string
}

export class Notification {
  id: string
  title: string
  sms?: string
  email?: string
  push?: string
  status: keyof typeof NotificationStatus
  amountSmsOpen?: number
  amountSmsSent?: number
  amountEmailOpen?: number
  amountEmailSent?: number
  amountPushOpen?: number
  amountPushSent?: number
  users: NotificationUsers[]
  createdAt: Date
  updatedAt: Date

  constructor(data: any = {}) {
    this.id = data._id || data.id || ""
    this.title = data.title || ""
    this.sms = data.sms || ""
    this.email = data.email || ""
    this.push = data.push || ""
    this.status = data.status
    this.users = data.users

    if (data.amountSmsOpen) this.amountSmsOpen = data.amountSmsOpen
    else this.amountSmsOpen = 0
    if (data.amountSmsSent) this.amountSmsSent = data.amountSmsSent
    else this.amountSmsSent = 0
    if (data.amountEmailOpen) this.amountEmailOpen = data.amountEmailOpen
    else this.amountEmailOpen = 0
    if (data.amountEmailSent) this.amountEmailSent = data.amountEmailSent
    else this.amountEmailSent = 0
    if (data.amountPushOpen) this.amountPushOpen = data.amountPushOpen
    else this.amountPushOpen = 0
    if (data.amountPushSent) this.amountPushSent = data.amountPushSent
    else this.amountPushSent = 0

    this.createdAt = data.createdAt ? new Date(data.createdAt) : new Date()
    this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : new Date()
  }
}
