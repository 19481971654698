import React, { useEffect, useState } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"

import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Image,
  Textarea,
  SimpleGrid,
  Button,
  ButtonGroup,
  useToast,
} from "@chakra-ui/react"
import { AiOutlineArrowLeft } from "react-icons/ai"

import { VehicleTransfer } from "../../../../entities"
import { ValidationError, ValidationErrorHandling } from "../../../../util"
import { VehicleTransferService } from "../../../../services"

import theme from "../../../../config/Themes"
import Formatter from "../../../../util/formatter"
import Loader from "../../../../components/Loader/Loader"
import UserStorage from "../../../../util/userStorage"

export default function VehicleTransferFormPage() {
  const navigate = useNavigate()
  const params = useParams()
  const toast = useToast()

  const [form, setForm] = useState(new VehicleTransfer({}))

  const [errors, setErrors] = useState<ValidationError[]>([])
  const [loading, setLoading] = useState(false)

  const [canTransfer, setCanTransfer] = useState(false)

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : 0

    if (id) {
      load(id)
    }

    const roleCode = UserStorage.get()?.role?.code
    if (
      ["admin", "support", "operational"].findIndex(
        (code) => code === roleCode
      ) >= 0
    ) {
      setCanTransfer(true)
    }

    // eslint-disable-next-line
  }, [params.id])

  const load = async (id: number) => {
    setLoading(true)
    VehicleTransferService.get(id)
      .then((vehicleTransfer: VehicleTransfer) => {
        setForm(vehicleTransfer)
      })
      .finally(() => setLoading(false))
  }

  const changeStatus = (newStatus: string) => {
    setLoading(true)
    VehicleTransferService.updateStatus(form.id, newStatus)
      .then((success) => {
        setLoading(false)
        if (success === false) {
          toast({
            title:
              "Não foi possível atualizar a transferência. Tente novamente.",
            status: "error",
            duration: 2000,
          })
          return
        }

        if (newStatus === "approved") {
          toast({
            title: "Transferência de veículo aceita.",
            status: "success",
            duration: 2000,
          })
        } else {
          toast({
            title: "Transferência de veículo rejeitada.",
            status: "success",
            duration: 2000,
          })
        }

        navigate("/vehicle/transfer")
      })
      .finally(() => setLoading(false))
  }

  return (
    <Layout>
      {loading && <Loader />}
      <Breadcrumbs
        items={[
          { label: "Dashboard", link: "/" },
          { label: "Transferência de Veículos", link: "/vehicle/transfer" },
          { label: "Visualizar", link: `/vehicle/transfer/${form.id}` },
        ]}
        additionalHeaderInfo={
          <>
            <Link to="/vehicle/transfer">
              <button>
                <AiOutlineArrowLeft />
              </button>
            </Link>
          </>
        }
      />
      <Flex
        w="100%"
        h="full"
        p="2rem"
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
          Transferência de Veículo
        </Text>
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 1 }}
          spacing="1rem"
        >
          <FormControl maxWidth="705px" isRequired isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Motivo</FormLabel>
            <Input
              type="text"
              name="reason"
              placeholder=""
              value={form.reason}
              onChange={(event) =>
                setForm({ ...form, reason: event.currentTarget.value })
              }
              onFocus={() =>
                setErrors(ValidationErrorHandling.remove("reason", errors))
              }
              readOnly
            />
          </FormControl>
          <FormControl maxWidth="705px" isRequired isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Explicação</FormLabel>
            <Textarea readOnly value={form.explanation}></Textarea>
          </FormControl>
        </SimpleGrid>

        <Text fontSize="1.5rem" fontWeight="500" color={theme.color.secondary}>
          Solicitante
        </Text>
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
          spacing="1rem"
        >
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Nome</FormLabel>
            <Input
              type="text"
              name="firstname"
              placeholder="Nome"
              value={form.vehicle?.user?.firstname}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Sobrenome</FormLabel>
            <Input
              type="text"
              name="lastname"
              placeholder="Sobrenome"
              value={form.vehicle?.user?.lastname}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>E-mail</FormLabel>
            <Input
              type="text"
              name="email"
              placeholder="E-mail"
              value={form.vehicle?.user?.email}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Telefone</FormLabel>
            <Input
              type="text"
              name="phone"
              placeholder="Telefone"
              value={form.vehicle?.user?.phone}
              readOnly
            />
          </FormControl>
        </SimpleGrid>

        <Text fontSize="1.5rem" fontWeight="500" color={theme.color.secondary}>
          Veículo Solicitante
        </Text>
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
          spacing="1rem"
        >
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Placa</FormLabel>
            <Input
              type="text"
              name="licensePlate"
              placeholder="ABC-0000"
              value={form.vehicle?.licensePlate}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Fabricante</FormLabel>
            <Input
              type="text"
              name="manufacturer"
              placeholder="Toyota"
              value={form.vehicle?.manufacturer}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Modelo</FormLabel>
            <Input
              type="text"
              name="model"
              placeholder="Corolla"
              value={form.vehicle?.model}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Cor</FormLabel>
            <Input
              type="text"
              name="color"
              placeholder="Prata"
              value={form.vehicle?.color}
              readOnly
            />
          </FormControl>
        </SimpleGrid>

        <Text fontSize="1.5rem" fontWeight="500" color={theme.color.secondary}>
          Dono Atual
        </Text>
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
          spacing="1rem"
        >
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Nome</FormLabel>
            <Input
              type="text"
              name="firstname"
              placeholder="Nome"
              value={form.vehicleActived?.user?.firstname}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Sobrenome</FormLabel>
            <Input
              type="text"
              name="lastname"
              placeholder="Sobrenome"
              value={form.vehicleActived?.user?.lastname}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>E-mail</FormLabel>
            <Input
              type="text"
              name="email"
              placeholder="E-mail"
              value={form.vehicleActived?.user?.email}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Telefone</FormLabel>
            <Input
              type="text"
              name="phone"
              placeholder="Telefone"
              value={form.vehicleActived?.user?.phone}
              readOnly
            />
          </FormControl>
        </SimpleGrid>

        <Text fontSize="1.5rem" fontWeight="500" color={theme.color.secondary}>
          Veículo Ativo
        </Text>
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
          spacing="1rem"
        >
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Placa</FormLabel>
            <Input
              type="text"
              name="licensePlate"
              placeholder="ABC-0000"
              value={form.vehicleActived?.licensePlate}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Fabricante</FormLabel>
            <Input
              type="text"
              name="manufacturer"
              placeholder="Toyota"
              value={form.vehicleActived?.manufacturer}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Modelo</FormLabel>
            <Input
              type="text"
              name="model"
              placeholder="Corolla"
              value={form.vehicleActived?.model}
              readOnly
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Cor</FormLabel>
            <Input
              type="text"
              name="color"
              placeholder="Prata"
              value={form.vehicleActived?.color}
              readOnly
            />
          </FormControl>
        </SimpleGrid>

        <Text fontSize="1.5rem" fontWeight="500" color={theme.color.secondary}>
          Documentos
        </Text>
        <Flex alignItems="center" wrap="wrap">
          {form.documents.length === 0 && <>Nenhum documento foi enviado.</>}
          {form.documents.map((document) => (
            <>
              {document.name.toLowerCase().endsWith("pdf") && (
                <a
                  href={`${process.env.REACT_APP_API_URI}/vehicle/transfer/document/image/${document.id}/${document.name}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {document.name}
                </a>
              )}
              {!document.name.toLowerCase().endsWith("pdf") && (
                <Image
                  width={200}
                  alt={document.name}
                  src={`${process.env.REACT_APP_API_URI}/vehicle/transfer/document/image/${document.id}/${document.name}`}
                  marginRight={5}
                />
              )}
            </>
          ))}
        </Flex>

        <FormControl maxWidth="705px" isRequired isInvalid={false}>
          <FormLabel color={theme.color.quaternary}>Situação</FormLabel>
          <Input
            type="text"
            name="status"
            value={Formatter.formatStatus(form.status)}
            readOnly
          />
        </FormControl>

        {form.status === "open" && canTransfer && (
          <>
            <Text>Ação</Text>
            <ButtonGroup>
              <Button onClick={() => changeStatus("approved")}>Aprovar</Button>
              <Button onClick={() => changeStatus("declined")}>Reprovar</Button>
            </ButtonGroup>
          </>
        )}
      </Flex>
    </Layout>
  )
}
