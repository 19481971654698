import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Flex,
  Button,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import { AiOutlineArrowLeft, AiOutlineConsoleSql } from "react-icons/ai"
import { ValidationError, ValidationErrorHandling } from "../../../../util"
import theme from "../../../../config/Themes"
import Loader from "../../../../components/Loader/Loader"
import { Notification } from "../../../../entities/csg/Notification"
import { NotificationService } from "../../../../services/csg/NotificationService"
import ReactSelect from "react-select"
import { SearchFilter, SearchParams, User } from "../../../../entities"
import { UserService } from "../../../../services"
import { useDebounce } from "react-use"

export default function NotificationFormPage() {
  const params = useParams()
  const navigate = useNavigate()
  const id = params.id

  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("")

  const toast = useToast()

  const [form, setForm] = useState(new Notification())
  const [errors, setErrors] = useState<ValidationError[]>([])
  const [loading, setLoading] = useState(false)
  const [customers, setCustomers] = useState<User[]>([])

  const handleLoadCustomers = () => {
    const filter: SearchFilter[] = []

    filter.push({
      key: "roleCode",
      operator: "eq",
      value: "customer",
    })

    if (debouncedSearchTerm != "")
      filter.push({
        key: "email",
        operator: "like",
        value: debouncedSearchTerm,
      })

    UserService.search(new SearchParams(filter, 1, 999_999)).then((data) =>
      setCustomers(data.data)
    )
  }

  const load = (id: string) => {
    setLoading(true)
    NotificationService.get(id)
      .then((data) => setForm(data))
      .finally(() => setLoading(false))
  }

  const save = () => {
    const notification = new Notification(form)

    NotificationService.create(notification)
      .then(() => {
        navigate("/notification")
      })
      .catch((error) => {
        toast({
          title: "Erro ao salvar!",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (id && !(id === "0")) load(id)

    handleLoadCustomers()
  }, [id])

  useEffect(() => {
    if (debouncedSearchTerm) handleLoadCustomers()
  }, [debouncedSearchTerm])

  useDebounce(
    () => {
      if (searchTerm) setDebouncedSearchTerm(searchTerm)
    },
    500,
    [searchTerm]
  )

  console.log(form.users)
  return (
    <Layout>
      {loading && <Loader />}
      <Breadcrumbs
        items={[
          { label: "Dashboard", link: "/" },
          { label: "Notificações", link: "/notification" },
          {
            label: "Cadastro de Notificações",
            link: `/notification/${form.id}`,
          },
        ]}
        additionalHeaderInfo={
          <>
            <Link to="/notification">
              <button>
                <AiOutlineArrowLeft />
              </button>
            </Link>
          </>
        }
      />
      <Flex
        w="100%"
        h="full"
        p="2rem"
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
          Cadastro de Notificação
        </Text>

        <FormControl isRequired isInvalid={false}>
          <FormLabel minHeight="3rem" color={theme.color.quaternary}>
            Título da Notificação
          </FormLabel>
          <Input
            type="text"
            name="title"
            placeholder=""
            value={form.title}
            onChange={(event) =>
              setForm({
                ...form,
                title: event.currentTarget.value,
              })
            }
            onFocus={() =>
              setErrors(ValidationErrorHandling.remove("title", errors))
            }
          />
          {ValidationErrorHandling.hasError("title", errors) && (
            <FormHelperText>
              {ValidationErrorHandling.getErrorMessage("title", errors)}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel minHeight="3rem" color={theme.color.quaternary}>
            Clientes
          </FormLabel>

          <ReactSelect
            isClearable
            placeholder="Selecione um cliente"
            options={customers.map((customer) => ({
              value: customer.id.toString(),
              label: `${customer.firstname} ${customer.lastname} / ${customer.phone} / ${customer.email}`,
            }))}
            menuPortalTarget={document.body}
            name="customers"
            defaultInputValue={searchTerm}
            onInputChange={(inputValue) => setSearchTerm(inputValue)}
            closeMenuOnSelect={false}
            isMulti
            value={form?.users?.map((user) => ({
              label: `${user.firstname} ${user.lastname}`,
              value: user.id.toString(),
            }))}
            inputValue={searchTerm}
            onChange={(selectedOptions, action) => {
              console.log(action)

              if (action.action === "clear") {
                setForm({
                  ...form,
                  users: [],
                })
                return
              }

              if (action.action === "remove-value") {
                const removedValue = action.removedValue

                setForm((prevForm) => {
                  const existingUsers = prevForm.users || []
                  const updatedUsers = existingUsers.filter(
                    (user) => user.id.toString() !== removedValue.value
                  )
                  return {
                    ...prevForm,
                    users: updatedUsers,
                  }
                })

                return
              }

              if (action.action === "select-option") {
                const updatedUsers = selectedOptions
                  .map((option) =>
                    customers.find((customer) => customer.id === +option.value)
                  )
                  .filter((customer) => Boolean(customer))

                setForm((prevForm: any) => {
                  const existingUsers = prevForm.users || []
                  const uniqueUsers = [
                    ...existingUsers,
                    ...updatedUsers.filter(
                      (newUser) =>
                        !existingUsers.some(
                          (user: any) => user.id === newUser?.id
                        )
                    ),
                  ]
                  return {
                    ...prevForm,
                    users: uniqueUsers,
                  }
                })

                return
              }
            }}
          />
        </FormControl>

        <FormControl isRequired isInvalid={false}>
          <FormLabel minHeight="3rem" color={theme.color.quaternary}>
            SMS
          </FormLabel>
          <Textarea
            placeholder="Digite seu sms aqui..."
            value={form.sms}
            onChange={(event) =>
              setForm({
                ...form,
                sms: event.currentTarget.value,
              })
            }
            onFocus={() =>
              setErrors(ValidationErrorHandling.remove("sms", errors))
            }
          />
          {ValidationErrorHandling.hasError("sms", errors) && (
            <FormHelperText>
              {ValidationErrorHandling.getErrorMessage("sms", errors)}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={false}>
          <FormLabel minHeight="3rem" color={theme.color.quaternary}>
            Push
          </FormLabel>
          <Textarea
            placeholder="Digite seu Push aqui..."
            value={form.push}
            onChange={(event) =>
              setForm({
                ...form,
                push: event.currentTarget.value,
              })
            }
            onFocus={() =>
              setErrors(ValidationErrorHandling.remove("push", errors))
            }
          />
          {ValidationErrorHandling.hasError("push", errors) && (
            <FormHelperText>
              {ValidationErrorHandling.getErrorMessage("push", errors)}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={false}>
          <FormLabel minHeight="3rem" color={theme.color.quaternary}>
            E-mail
          </FormLabel>
          <Textarea
            placeholder="Digite seu E-mail aqui..."
            value={form.email}
            onChange={(event) =>
              setForm({
                ...form,
                email: event.currentTarget.value,
              })
            }
          />

          {ValidationErrorHandling.hasError("email", errors) && (
            <FormHelperText>
              {ValidationErrorHandling.getErrorMessage("email", errors)}
            </FormHelperText>
          )}
        </FormControl>

        {id !== "0" && (
          <>
            <FormControl>
              <FormLabel minHeight="3rem">SMS Abertos</FormLabel>
              <Input
                type="number"
                readOnly
                value={form.amountSmsOpen}
                onFocus={() =>
                  setErrors(
                    ValidationErrorHandling.remove("amountSmsOpen", errors)
                  )
                }
              />
              {ValidationErrorHandling.hasError("amountSmsOpen", errors) && (
                <FormHelperText>
                  {ValidationErrorHandling.getErrorMessage(
                    "amountSmsOpen",
                    errors
                  )}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel minHeight="3rem">SMS Enviados</FormLabel>
              <Input
                type="number"
                readOnly
                value={form.amountSmsSent}
                onFocus={() =>
                  setErrors(
                    ValidationErrorHandling.remove("amountSmsSent", errors)
                  )
                }
              />
              {ValidationErrorHandling.hasError("amountSmsSent", errors) && (
                <FormHelperText>
                  {ValidationErrorHandling.getErrorMessage(
                    "amountSmsSent",
                    errors
                  )}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel minHeight="3rem">Emails Abertos</FormLabel>
              <Input
                type="number"
                readOnly
                value={form.amountEmailOpen}
                onFocus={() =>
                  setErrors(
                    ValidationErrorHandling.remove("amountEmailOpen", errors)
                  )
                }
              />
              {ValidationErrorHandling.hasError("amountEmailOpen", errors) && (
                <FormHelperText>
                  {ValidationErrorHandling.getErrorMessage(
                    "amountEmailOpen",
                    errors
                  )}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel minHeight="3rem">Emails Enviados</FormLabel>
              <Input
                type="number"
                readOnly
                value={form.amountEmailSent}
                onFocus={() =>
                  setErrors(
                    ValidationErrorHandling.remove("amountEmailSent", errors)
                  )
                }
              />
              {ValidationErrorHandling.hasError("amountEmailSent", errors) && (
                <FormHelperText>
                  {ValidationErrorHandling.getErrorMessage(
                    "amountEmailSent",
                    errors
                  )}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel minHeight="3rem">Notificações Push Abertos</FormLabel>
              <Input
                type="number"
                readOnly
                value={form.amountPushOpen}
                onFocus={() =>
                  setErrors(
                    ValidationErrorHandling.remove("amountPushOpen", errors)
                  )
                }
              />
              {ValidationErrorHandling.hasError("amountPushOpen", errors) && (
                <FormHelperText>
                  {ValidationErrorHandling.getErrorMessage(
                    "amountPushOpen",
                    errors
                  )}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel minHeight="3rem">Notificações Push Enviados</FormLabel>
              <Input
                type="number"
                readOnly
                value={form.amountPushSent}
                onFocus={() =>
                  setErrors(
                    ValidationErrorHandling.remove("amountPushSent", errors)
                  )
                }
              />
              {ValidationErrorHandling.hasError("amountPushSent", errors) && (
                <FormHelperText>
                  {ValidationErrorHandling.getErrorMessage(
                    "amountPushSent",
                    errors
                  )}
                </FormHelperText>
              )}
            </FormControl>
          </>
        )}

        <Flex mt="1rem">
          <Button
            type="submit"
            minW="95px"
            size="md"
            borderRadius="0.375rem"
            bg={theme.color.primary}
            fontFamily="Raleway"
            fontSize="0.875rem"
            fontWeight="600"
            color={theme.color.tertiary}
            _hover={{ bg: `${theme.color.primary}` }}
            onClick={() => save()}
          >
            Enviar
          </Button>
        </Flex>
      </Flex>
    </Layout>
  )
}
