const theme = {
  color: {
    primary: process.env.REACT_APP_THEME_COLOR_PRIMARY || "#0F0F12",
    secondary: process.env.REACT_APP_THEME_COLOR_SECONDARY || "#006FFFE5",
    tertiary: process.env.REACT_APP_THEME_COLOR_TERTIARY || "#ffffff",
    quaternary: process.env.REACT_APP_THEME_COLOR_QUATERNARY || "#61799B",
    quinary: process.env.REACT_APP_THEME_COLOR_QUINARY || "#ABB7C8",
    senary: process.env.REACT_APP_THEME_COLOR_SENARY || "#7F8896",
  },
  logo: {
    url: process.env.REACT_APP_THEME_LOGO_URL || "/images/movvia-logo-black.png",
  },
  banner: {
    //url: process.env.REACT_APP_THEME_LOGO_URL || "/images/",
  },
  company: {
    name: process.env.REACT_APP_THEME_COMPANY_NAME || "Movvia",
  },
  copyright: {
    text: process.env.REACT_APP_THEME_COPYRIGHT_TEXT || `© ${(new Date().getFullYear())} - Todos os direitos reservados.`,
  }
}

export default theme