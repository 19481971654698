import {
  BsBell,
  BsBuildingCheck,
  BsCarFrontFill,
  BsCash,
  BsCoin,
  BsCreditCard2Front,
  BsDoorOpenFill,
  BsFillQuestionCircleFill,
  BsFillShieldFill,
  BsHouseDoorFill,
  BsPersonCircle,
  BsPersonFill,
} from "react-icons/bs"
import { MdAdminPanelSettings } from "react-icons/md"
import { FaUmbrellaBeach } from "react-icons/fa6"
import UserStorage from "../util/userStorage"

export function getMenuItems() {
  const user = UserStorage.get()

  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      hasPermission: true,
      icon: <BsHouseDoorFill />,
    },
    {
      title: "Clientes",
      to: "/customer",
      hasPermission:
        user?.role?.code === "admin" ||
        user?.role?.code === "financial" ||
        user?.role?.code === "support" ||
        user?.role?.code === "operational",
      icon: <BsPersonFill />,
    },
    {
      title: "Cobranças",
      to: "/charge",
      hasPermission:
        user?.role?.code === "admin" ||
        user?.role?.code === "financial" ||
        user?.role?.code === "support" ||
        user?.role?.code === "operational",
      icon: <BsCash />,
    },
    {
      title: "Pagamentos",
      to: "/payment",
      hasPermission:
        user?.role?.code === "admin" ||
        user?.role?.code === "financial" ||
        user?.role?.code === "support" ||
        user?.role?.code === "operational",
      icon: <BsCreditCard2Front />,
    },
    {
      title: "Pagamentos BSO",
      to: "/payment/bso",
      hasPermission:
        user?.role?.code === "admin" ||
        user?.role?.code === "financial" ||
        user?.role?.code === "operational",
      icon: <BsBuildingCheck />,
    },
    {
      title: "Transferências",
      to: "/vehicle/transfer",
      hasPermission:
        user?.role?.code === "admin" ||
        user?.role?.code === "support" ||
        user?.role?.code === "operational",
      icon: <BsCarFrontFill />,
    },
    {
      title: "Resgate de Saldo",
      to: "/balance-return",
      hasPermission:
        user?.role?.code === "admin" || user?.role?.code === "financial",
      icon: <BsCoin />,
    },
    {
      title: "Usuários",
      to: "/user",
      hasPermission: user?.role?.code === "admin",
      icon: <BsPersonCircle />,
    },
    {
      title: "Segurança",
      to: "/security",
      hasPermission:
        user?.role?.code === "admin" ||
        user?.role?.code === "support" ||
        user?.role?.code === "operational",
      icon: <BsFillShieldFill />,
    },
    {
      title: "IPs liberados",
      to: "/allowed-ip",
      hasPermission: user?.role?.code === "admin",
      icon: <MdAdminPanelSettings />,
    },
    {
      title: "Feriados",
      to: "/holiday",
      hasPermission: user?.role?.code === "admin",
      icon: <FaUmbrellaBeach />,
    },
    {
      title: "Ajuda",
      to: "/help",
      hasPermission:
        user?.role?.code === "admin" ||
        user?.role?.code === "financial" ||
        user?.role?.code === "support",
      icon: <BsFillQuestionCircleFill />,
    },
    {
      title: "Sair",
      to: "/login",
      hasPermission: true,
      icon: <BsDoorOpenFill />,
    },
  ]
}
