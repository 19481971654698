import React from "react"
import { BrowserRouter } from "react-router-dom"
import Router from "./pages/Router"
import "./App.scss"
import { ChakraProvider } from "@chakra-ui/react"

import theme from "../src/config/Themes"

export default function App() {
  const root = document.documentElement
  root.style.setProperty("--color-primary", theme.color.primary)
  root.style.setProperty("--color-secondary", theme.color.secondary)
  root.style.setProperty("--color-tertiary", theme.color.quaternary)
  root.style.setProperty("--color-quaternary", theme.color.quaternary)
  root.style.setProperty("--color-quinary", theme.color.tertiary)
  //root.style.setProperty("--color-senary", theme.color.senary)
  //root.style.setProperty("--color-septenary", theme.color.septenary)
  //root.style.setProperty("--color-octonary", theme.color.octonary)
  //root.style.setProperty("--color-nonary", theme.color.nonary)
  //root.style.setProperty("--color-denary", theme.color.denary)
  //root.style.setProperty("--color-undenary", theme.color.undenary)
  //root.style.setProperty("--color-duodenary", theme.color.duodenary)
  //root.style.setProperty("--color-tredenary", theme.color.tredenary)
  // root.style.setProperty("--color-quattuordenary", theme.color.quattuordenary)

  return (
    <BrowserRouter>
      <div className="App">
        <ChakraProvider>
          <Router />
        </ChakraProvider>
      </div>
    </BrowserRouter>
  )
}
