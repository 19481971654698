import { Button, Flex } from "@chakra-ui/react"
import DataTable from "../../../../components/dataTable/DataTable"
import {
  BalanceReturnBulk,
  SearchFilter,
  SearchParams,
  SearchResultInfo,
} from "../../../../entities"
import { useEffect, useState } from "react"
import Formatter from "../../../../util/formatter"
import { Link as ChakraLink } from "@chakra-ui/react"
import { Link as ReactRouterLink } from "react-router-dom"
import { GrView } from "react-icons/gr"
import { BalanceReturnBulkService } from "../../../../services/csg/BalanceReturnBulkService"
import theme from "../../../../config/Themes"

interface BalanceReturnListBulkProps {
  loading: boolean
  setLoading: (loading: boolean) => void
  reload: string
  setReload: (reload: string) => void
  filters: {
    createdAtFrom: string
    createdAtTo: string
    name: string
    status: string
  }
  canApprove: boolean
}

export default function BalanceReturnListBulk({
  loading,
  setLoading,
  reload,
  setReload,
  filters,
}: BalanceReturnListBulkProps) {
  const [balancesReturnBulk, setBalancesReturnBulk] = useState<
    BalanceReturnBulk[]
  >([])
  const [resultInfo, setResultInfo] = useState(new SearchResultInfo())

  useEffect(() => {
    if (reload === "list-bulk") load(1)
    if (reload === "csv-bulk") downloadCsv()
    // eslint-disable-next-line
  }, [reload])

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [])

  const getFilters = (): SearchFilter[] => {
    const searchFilters: SearchFilter[] = []

    if (filters.createdAtFrom) {
      searchFilters.push(
        new SearchFilter({
          key: "createdAtFrom",
          value: filters.createdAtFrom,
          operator: "eq",
        })
      )
    }

    if (filters.createdAtTo) {
      searchFilters.push(
        new SearchFilter({
          key: "createdAtTo",
          value: filters.createdAtTo,
          operator: "eq",
        })
      )
    }

    if (filters.status) {
      searchFilters.push(
        new SearchFilter({
          key: "status",
          value: filters.status,
          operator: "eq",
        })
      )
    }

    if (filters.name) {
      searchFilters.push(
        new SearchFilter({ key: "name", value: filters.name, operator: "like" })
      )
    }

    return searchFilters
  }

  const load = async (page = 1) => {
    const filters = getFilters()
    const searchParams = new SearchParams(filters, page, 10)

    setLoading(true)
    BalanceReturnBulkService.search(searchParams)
      .then((result) => {
        setBalancesReturnBulk(result.data)
        setResultInfo(result.info)
      })
      .finally(() => {
        setLoading(false)
        setReload("")
      })
  }

  const downloadCsv = () => {
    const filters = getFilters()
    const searchParams = new SearchParams(filters, 1, 999999999)

    setLoading(true)
    BalanceReturnBulkService.csv(searchParams)
      .then((href: string) => {
        const tempLink = document.createElement("a")
        tempLink.href = href
        tempLink.setAttribute("download", "balance-return-bulk.csv")
        tempLink.click()
      })
      .finally(() => {
        setLoading(false)
        setReload("")
      })
  }

  const renderCreatedAt = (params: any) => {
    return Formatter.formatDateTime(new Date(params.createdAt))
  }

  const renderTotal = (params: any) => {
    return Formatter.formatCurrency(params.total)
  }

  const renderStatus = (params: any) => {
    return Formatter.formatStatus(params.status)
  }

  const renderActions = (params: any) => {
    return (
      <div>
        <ChakraLink
          as={ReactRouterLink}
          to={`/balance-return/bulk/${params.id}`}
        >
          <Button
            bg="#E7ECFF"
            color={theme.color.primary}
            transition="0.2s"
            _hover={{
              bg: `${theme.color.secondary}`,
              color: `${theme.color.tertiary}`,
            }}
          >
            <GrView />
          </Button>
        </ChakraLink>
      </div>
    )
  }

  const columns = [
    { field: "name", headerName: "Lote", mWidth: "50%" },
    {
      renderCell: renderCreatedAt,
      headerName: "Data",
      mWidth: "100%",
    },
    {
      renderCell: renderTotal,
      headerName: "Valor Total do Lote",
      mWidth: "30%",
    },
    { renderCell: renderStatus, headerName: "Status", mWidth: "35%" },
    {
      headerName: "Ações",
      renderCell: renderActions,
      mWidth: "60%",
      mAlign: "end",
      mDisplay: "none",
    },
  ]

  return (
    <Flex w="100%">
      <DataTable
        checkboxSelection={false}
        rows={balancesReturnBulk}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />
    </Flex>
  )
}
