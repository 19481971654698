import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
} from "@chakra-ui/react"
import { BiTrash } from "react-icons/bi"
import theme from "../../config/Themes"

interface DeleteModalProps {
  handleDelete: () => void
}

export function DeleteModal({ handleDelete, ...props }: DeleteModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleDeleteConfirm = () => {
    handleDelete()
    onClose()
  }

  return (
    <Flex w="100%" justifyContent="flex-end">
      <Button onClick={onOpen} {...props}>
        <BiTrash onClick={onOpen} />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Você tem certeza que deseja realizar essa ação ?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              color={theme.color.tertiary}
              variant="ghost"
              background="red"
              _hover={{
                background: "red.600",
              }}
              onClick={handleDeleteConfirm}
            >
              Deletar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
