import React, { useEffect, useState } from "react"
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
} from "react-router-dom"
import InputMask from "react-input-mask"
import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Flex,
  SimpleGrid,
  ButtonGroup,
  Button,
  useToast,
  Select,
  Link as ChakraLink,
} from "@chakra-ui/react"
import { AiOutlineArrowLeft } from "react-icons/ai"

import {
  SearchResultInfo,
  User,
  Vehicle,
  Charge,
  Transaction,
  States,
  SearchParams,
  SearchFilter,
} from "../../../../entities"
import {
  BankAccountService,
  ChargeService,
  UserService,
  VehicleService,
} from "../../../../services"
import DataTable from "../../../../components/dataTable/DataTable"
import UserStorage from "../../../../util/userStorage"
import Loader from "../../../../components/Loader/Loader"
import { ValidationInput } from "../../../../util/ValidationInput"
import { GrView } from "react-icons/gr"
import { DeleteModal } from "../../../../components/shared/delete-modal"
import Formatter from "../../../../util/formatter"
import { BsDownload } from "react-icons/bs"
import { isValid } from "date-fns"
import theme from "../../../../config/Themes"

export default function CustomerFormPage() {
  const today = new Date()
  const day = ("0" + today.getDate()).slice(-2)
  const month = ("0" + (today.getMonth() + 1)).slice(-2)
  const user = UserStorage.get()

  const params = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const [form, setForm] = useState(new User({}))
  const [resultInfo] = useState(new SearchResultInfo())
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [charges, setCharges] = useState<Charge[]>([])
  const [balanceFormated, setBalanceFormated] = useState("")
  const [transactions, setTransactions] = useState<Transaction[]>([])

  const [canEdit, setCanEdit] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [licensePlate, setLicensePlate] = useState("")
  const [occurrenceFrom, setOccurrenceFrom] = useState(
    today.getFullYear() + "-" + month + "-" + `0${1}`
  )
  const [occurrenceTo, setOccurrenceTo] = useState(
    today.getFullYear() + "-" + month + "-" + day
  )
  const [status, setStatus] = useState("")
  const [paymentMethod, setPaymentMethod] = useState("")

  const getChargeFilters = (): SearchFilter[] => {
    const filters: SearchFilter[] = []
    if (licensePlate) {
      filters.push(
        new SearchFilter({
          key: "licensePlate2",
          value: licensePlate,
          operator: "eq",
        })
      )
    }

    if (occurrenceFrom) {
      filters.push(
        new SearchFilter({
          key: "occurrenceFrom",
          value: occurrenceFrom,
          operator: "eq",
        })
      )
    }

    if (occurrenceTo) {
      filters.push(
        new SearchFilter({
          key: "occurrenceTo",
          value: occurrenceTo,
          operator: "eq",
        })
      )
    }

    if (status) {
      filters.push(
        new SearchFilter({ key: "status", value: status, operator: "eq" })
      )
    }

    if (paymentMethod) {
      filters.push(
        new SearchFilter({
          key: "paymentMethod",
          value: paymentMethod,
          operator: "eq",
        })
      )
    }

    return filters
  }

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : 0

    if (id) {
      load(id)
      loadVehicles(id)
      loadCharges(1)
      loadTransactions(id)
    }

    const roleCode = UserStorage.get()?.role?.code
    if (
      ["admin", "financial", "operational"].findIndex(
        (code) => code === roleCode
      ) >= 0
    ) {
      setCanEdit(true)
    }

    // eslint-disable-next-line
  }, [params.id])

  const load = async (id: number) => {
    setIsLoading(true)
    UserService.get(id)
      .then((user) => {
        setForm(user)
      })
      .finally(() => setIsLoading(false))
  }

  const loadVehicles = (userId: number) => {
    VehicleService.getByUser(userId, true).then((result) => {
      setVehicles(result.data)
    })
  }

  const loadCharges = (page: number) => {
    const filters = getChargeFilters()

    filters.push({
      key: "userId",
      value: params.id?.toString() || "",
      operator: "eq",
    })

    setIsLoading(true)
    ChargeService.search(new SearchParams(filters, page, 10))
      .then((result) => {
        setCharges(result.data)
      })
      .finally(() => setIsLoading(false))
  }

  const loadTransactions = (userId: number) => {
    BankAccountService.getByUser(userId).then((bankAccount) => {
      console.log("bank-account", bankAccount)
      setBalanceFormated(bankAccount.balanceFormated)
      setTransactions(bankAccount.transactions)
      // setVehicles(result.data)
    })
  }

  const handleRemovePlate = (plateId: string) => {
    VehicleService.delete(plateId)
      .then(() => {
        toast({
          title: "Placa removida com sucesso.",
          status: "success",
          duration: 2000,
        })
      })
      .catch(() => {
        toast({
          title: "Falha ao remover a placa.",
          status: "error",
          duration: 2000,
        })
      })
      .finally(() => {
        loadVehicles(form.id)
      })
  }

  const renderType = (params: any) => {
    if (params.type === "add-balance") return "Adicionou saldo"
    if (params.type === "debit") return "Pagamento"
    if (params.type === "redeem") return "Reembolso"
    if (params.type === "redeem-return") return "Reembolso Devolvido"
  }

  const renderIsActive = (params: any) => {
    return params.isActive === true ? "Sim" : "Não"
  }

  const renderIsRegular = (params: any) => {
    return params.isRegular === true ? "Sim" : "Não"
  }

  const renderIsDeleted = (params: any) => {
    return params.deletedAt
      ? Formatter.formatDateTime(new Date(params.deletedAt))
      : ""
  }

  const renderActions = (params: any) => {
    if (params.deletedAt) return null

    return <DeleteModal handleDelete={() => handleRemovePlate(params.id)} />
  }

  const downloadCsv = () => {
    const filters = getChargeFilters()
    const searchParams = new SearchParams(filters, 1, 999999999)

    setIsLoading(true)
    ChargeService.csv(searchParams)
      .then((href: string) => {
        const tempLink = document.createElement("a")
        tempLink.href = href
        tempLink.setAttribute("download", "charges.csv")
        tempLink.click()
      })
      .finally(() => setIsLoading(false))
  }

  const vehicleColumns = [
    { field: "licensePlate", headerName: "Placa" },
    { field: "manufacturer", headerName: "Marca" },
    { field: "model", headerName: "Modelo" },
    { field: "color", headerName: "Cor" },
    { renderCell: renderIsRegular, headerName: "Regular?" },
    { renderCell: renderIsActive, headerName: "Ativo?" },
    { field: "createdAtFormated", headerName: "Criação" },
    { renderCell: renderIsDeleted, headerName: "Removido?" },
    {
      headerName: "Ações",
      renderCell: renderActions,
    },
  ]

  const renderChargeActions = (params: any) => {
    return (
      <div>
        <ChakraLink
          as={ReactRouterLink}
          to={`/charge/${params.id}`}
          target="_blank"
        >
          <Button
            bg="#E7ECFF"
            color={theme.color.primary}
            transition="0.2s"
            _hover={{
              bg: `${theme.color.secondary}`,
              color: `${theme.color.tertiary}`,
            }}
          >
            <GrView />
          </Button>
        </ChakraLink>
      </div>
    )
  }

  const chargeColumns = [
    { field: "datetimeOccurrenceFormated", headerName: "Data de Ocorrência" },
    { field: "licensePlate", headerName: "Placa" },
    { field: "plazaDescription", headerName: "Praça" },
    { field: "laneCode", headerName: "Faixa" },
    { field: "rateAmountFormated", headerName: "Valor" },
    { field: "statusFormated", headerName: "Status" },
    { renderCell: renderChargeActions, headerName: "Ações" },
  ]

  const transactionColumns = [
    { field: "createdAtFormated", headerName: "Data" },
    { renderCell: renderType, headerName: "Tipo" },
    { field: "code", headerName: "Código" },
    { field: "paymentMethodFormated", headerName: "Forma de Pagamento" },
    { field: "amountFormated", headerName: "Valor" },
    { field: "statusFormated", headerName: "Status" },
  ]

  const save = () => {
    const user = new User(form)
    user.roleId = user.role.id

    if (user.firstname.length <= 2) {
      toast({
        title: "Digite o primeiro nome.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.lastname.length <= 2) {
      toast({
        title: "Digite o sobrenome.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.type === "PF") {
      if (user.document.length < 14) {
        toast({
          title: "Digite o CPF.",
          status: "error",
          duration: 2000,
        })
        return
      } else {
        if (!ValidationInput.isValidCPF(user.document)) {
          toast({
            title: "O CPF informado é inválido.",
            status: "error",
            duration: 2000,
          })
          return
        }
      }
    } else {
      if (user.document.length < 18) {
        toast({
          title: "Digite o CNPJ.",
          status: "error",
          duration: 2000,
        })
        return
      } else {
        if (!ValidationInput.isValidCNPJ(user.document)) {
          toast({
            title: "O CNPJ informado é inválido.",
            status: "error",
            duration: 2000,
          })
          return
        }
      }
      if (user.companyName.length < 3) {
        toast({
          title: "Digite a Razão Social.",
          status: "error",
          duration: 2000,
        })
        return
      }
      if (user.businessName.length < 3) {
        toast({
          title: "Digite o Nome Fantasia.",
          status: "error",
          duration: 2000,
        })
        return
      }
      if (user.post.length < 3) {
        toast({
          title: "Digite o Cargo.",
          status: "error",
          duration: 2000,
        })
        return
      }
    }

    if (user.postcode.length < 9) {
      toast({
        title: "Digite o CEP.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.address1.length < 3) {
      toast({
        title: "Digite o Endereço.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.city.length < 3) {
      toast({
        title: "Digite a Cidade.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.state.length !== 2) {
      toast({
        title: "Escolha o Estado.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.address2.length < 3) {
      toast({
        title: "Digite o Bairro.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.addressNumber.length < 1) {
      toast({
        title: "Digite o Nº.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.phone.length < 15) {
      toast({
        title: "Digite o Telefone",
        status: "error",
        duration: 2000,
      })
      return
    }

    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)
    if (!isEmailValid) {
      toast({
        title: "Digite um e-mail válido.",
        status: "error",
        duration: 2000,
      })
      return
    }

    setIsLoading(true)
    UserService.update(user)
      .then((result) => navigate("/customer"))
      .catch((error) => {
        toast({
          title: error?.response.data?.message,
          status: "error",
          duration: 2000,
        })
      })
      .finally(() => setIsLoading(false))
  }

  const remove = () => {
    const resp = window.confirm(
      `Deseja remover o cliente ${form.firstname} ${form.lastname}?`
    )
    if (!resp) return

    UserService.delete(form.id).then((success) => {
      if (!success) {
        alert("Falha ao remover o usuário")
        return
      }

      alert("Usuário removido com sucesso.")

      navigate("/customer")
    })
  }

  return (
    <Layout>
      {isLoading && <Loader />}
      <Breadcrumbs
        items={[
          { label: "Dashboard", link: "/" },
          { label: "Clientes", link: "/customer" },
          { label: "Visualizar", link: `/customer/${form.id}` },
        ]}
        additionalHeaderInfo={
          <>
            <ReactRouterLink to="/customer">
              <button>
                <AiOutlineArrowLeft />
              </button>
            </ReactRouterLink>
          </>
        }
      />
      <Flex
        w="100%"
        h="full"
        p="2rem"
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
          Cliente
        </Text>
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
          spacing="1rem"
        >
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>E-mail</FormLabel>
            <Input
              type="text"
              name="email"
              value={form.email}
              onChange={(event) =>
                setForm({ ...form, email: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Nome</FormLabel>
            <Input
              type="text"
              name="firstname"
              value={form.firstname}
              onChange={(event) =>
                setForm({ ...form, firstname: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Sobrenome</FormLabel>
            <Input
              type="text"
              name="lastname"
              value={form.lastname}
              onChange={(event) =>
                setForm({ ...form, lastname: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>
              {form.type === "PF" ? "CPF" : "CNPJ"}
            </FormLabel>
            <Input
              type="text"
              name="document"
              value={form.document}
              as={InputMask}
              mask={
                form.type === "PF" ? "999.999.999-99" : "99.999.999/9999-99"
              }
              maskChar={null}
              onChange={(event) =>
                setForm({ ...form, document: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          {form.type === "PF" && (
            <FormControl isRequired>
              <FormLabel>Data de nascimento</FormLabel>
              <Input
                size="lg"
                type="date"
                value={
                  form.birthDate &&
                  typeof form.birthDate === "string" &&
                  !isValid(form.birthDate)
                    ? form.birthDate
                    : Formatter.formatDateForInput(form.birthDate as Date)
                }
                onChange={(event) => {
                  const newDate = event.currentTarget.value

                  setForm({ ...form, birthDate: newDate })
                }}
                placeholder="01/01/2000"
              />
            </FormControl>
          )}
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>CEP</FormLabel>
            <Input
              type="text"
              name="postcode"
              value={form.postcode}
              onChange={(event) =>
                setForm({ ...form, postcode: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Endereço</FormLabel>
            <Input
              type="text"
              name="address1"
              value={form.address1}
              onChange={(event) =>
                setForm({ ...form, address1: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Cidade</FormLabel>
            <Input
              type="text"
              name="city"
              value={form.city}
              onChange={(event) =>
                setForm({ ...form, city: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Estado</FormLabel>
            <Select
              size="lg"
              placeholder="Estado"
              disabled={!canEdit}
              value={form.state}
              onChange={(event) =>
                setForm({ ...form, state: event.target.value })
              }
            >
              {States.map((item: any) => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Bairro</FormLabel>
            <Input
              type="text"
              name="address2"
              value={form.address2}
              onChange={(event) =>
                setForm({ ...form, address2: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Número</FormLabel>
            <Input
              type="text"
              name="addressNumber"
              value={form.addressNumber}
              onChange={(event) =>
                setForm({ ...form, addressNumber: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Complemento</FormLabel>
            <Input
              type="text"
              name="addressComplement"
              value={form.addressComplement}
              onChange={(event) =>
                setForm({ ...form, addressComplement: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.quaternary}>Telefone</FormLabel>
            <Input
              type="text"
              name="phone"
              value={form.phone}
              onChange={(event) =>
                setForm({ ...form, phone: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
        </SimpleGrid>

        <Text
          fontSize="1.2rem"
          fontWeight="600"
          color={theme.color.secondary}
          mt="1rem"
        >
          Veículos
        </Text>
        <DataTable
          title="Veículos"
          checkboxSelection={false}
          rows={vehicles}
          columns={vehicleColumns}
          pagination={resultInfo}
          onPageChange={(page) => load(page)}
          loading={isLoading}
        />

        <Text
          fontSize="1.2rem"
          fontWeight="600"
          color={theme.color.secondary}
          mt="1rem"
        >
          Cobranças
        </Text>

        <Flex
          w="auto"
          ml="auto"
          alignSelf="flex-end"
          justifyContent={{
            base: "flex-end",
            sm: "flex-end",
            md: "flex-end",
            lg: "flex-end",
          }}
          gap="1.25rem"
          alignItems="flex-end"
          direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
        >
          <FormControl
            w="100%"
            maxW={{ base: "100%", sm: "100%", md: "100%", lg: "648px" }}
            isInvalid={false}
            display="flex"
            justifyContent="flex-end"
            gap="0.5rem"
            flexWrap="wrap"
          >
            <Input
              maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Período inicial"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="date"
              name="from"
              value={occurrenceFrom}
              onChange={(e) => setOccurrenceFrom(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Período final"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="date"
              name="to"
              value={occurrenceTo}
              onChange={(e) => setOccurrenceTo(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "154px", md: "154px", lg: "154px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD", textTransform: "capitalize" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="licensePlate"
              placeholder="Placa"
              textTransform="uppercase"
              value={licensePlate}
              onChange={(e) => setLicensePlate(e.target.value.toUpperCase())}
            />
            <Select
              maxW={{ base: "100%", sm: "120px", md: "120px", lg: "120px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Status"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Todos</option>
              <option value="open">Aberto</option>
              <option value="paid">Pago</option>
            </Select>
            <Select
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Método de pagamento"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="">Todos</option>
              <option value="credit-card">Cartão de Crédito</option>
              <option value="pix">Pix</option>
              <option value="cash">Dinheiro</option>
              <option value="balance">Crédito</option>
              <option value="vvp">Vale</option>
              <option value="credit-card-pinpad">
                Cartão de Crédito Totem
              </option>
              <option value="debit-card-pinpad">Cartão de Débito Totem</option>
            </Select>
          </FormControl>
          <Flex gap="1rem">
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg={theme.color.primary}
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color={theme.color.tertiary}
              _hover={{ bg: `${theme.color.primary}` }}
              onClick={() => loadCharges(1)}
            >
              Buscar
            </Button>
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#F7F8F9"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#1A202C"
              _hover={{ bg: "#F7F8F9" }}
              onClick={() => downloadCsv()}
              display="flex"
              gap="0.5rem"
              alignItems="center"
              justifyContent="center"
            >
              <BsDownload />
              CSV
            </Button>
          </Flex>
        </Flex>

        <DataTable
          title="Cobranças"
          checkboxSelection={false}
          rows={charges}
          columns={chargeColumns}
          pagination={resultInfo}
          onPageChange={(page: number) => loadCharges(page)}
          loading={isLoading}
        />

        <Text
          fontSize="1.2rem"
          fontWeight="600"
          color={theme.color.secondary}
          mt="1rem"
        >
          Saldo: {balanceFormated}
        </Text>

        <Text
          fontSize="1.2rem"
          fontWeight="600"
          color={theme.color.secondary}
          mt="1rem"
        >
          Transações
        </Text>
        <DataTable
          title="Transações"
          checkboxSelection={false}
          rows={transactions}
          columns={transactionColumns}
          pagination={resultInfo}
          onPageChange={(page: number) => load(page)}
          loading={isLoading}
        />

        <ButtonGroup>
          {(user?.role?.code === "admin" ||
            user?.role?.code === "financial" ||
            user?.role?.code === "support" ||
            user?.role?.code === "operational") && (
            <Button onClick={save}>Salvar</Button>
          )}

          {(user?.role?.code === "admin" || user?.role?.code === "support") && (
            <Button onClick={remove}>Remover</Button>
          )}
        </ButtonGroup>
      </Flex>
    </Layout>
  )
}
