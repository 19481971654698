import { SearchParams, SearchResult } from "../../entities"
import { Notification } from "../../entities/csg/Notification"
import { NotificationRepository } from "../../repositories/csg/NotificationRepository"
import UserStorage from "../../util/userStorage"

export class NotificationService {
  public static async search(
    params: SearchParams
  ): Promise<SearchResult<Notification>> {
    const token = UserStorage.getToken()

    const result = await NotificationRepository.search(params, token)
    return result
  }

  public static async get(id: string): Promise<Notification> {
    const token = UserStorage.getToken()
    return await NotificationRepository.get(id, token)
  }

  public static async create(data: Notification): Promise<Notification> {
    const token = UserStorage.getToken()
    return await NotificationRepository.create(data, token)
  }

  public static async update(data: Notification): Promise<Notification> {
    const token = UserStorage.getToken()
    return await NotificationRepository.update(data, token)
  }

  public static async delete(id: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await NotificationRepository.delete(id, token)
  }
}
