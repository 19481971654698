import React, { useState } from "react"
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Link as LinkChakra,
} from "@chakra-ui/react"

import theme from "../../config/Themes"

export default function RegisterPage() {
  const [name, setName] = useState("")
  const handleNameChange = (event: any) => setName(event.target.value)
  const isNameError = name === ""

  const [email, setEmail] = useState("")
  const handleEmailChange = (event: any) => setEmail(event.target.value)
  const isEmailError = email === ""

  const [password, setPassword] = useState("")
  const handlePasswordChange = (event: any) => setPassword(event.target.value)
  const isPasswordError = password === ""

  return (
    <Flex w="100%" minH="100vh" direction="column" justifyContent="center">
      <Flex p="1rem" direction="column" alignItems="center">
        <Flex
          w="100%"
          maxWidth="768px"
          mb="1rem"
          direction="column"
          gap="1.5rem"
        >
          <FormControl isRequired isInvalid={isNameError}>
            <FormLabel>Nome completo</FormLabel>
            <Input
              size="lg"
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder="Seu nome completo"
            />
            {isNameError && (
              <FormErrorMessage>
                O seu nome completo é necessário.
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={isEmailError}>
            <FormLabel>E-mail</FormLabel>
            <Input
              size="lg"
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="email@provedor.com"
            />
            {isEmailError && (
              <FormErrorMessage>E-mail é necessário.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={isPasswordError}>
            <FormLabel>Senha</FormLabel>
            <Input
              size="lg"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="••••••••••••••"
            />
            {isPasswordError && (
              <FormErrorMessage>Senha é necessária.</FormErrorMessage>
            )}
          </FormControl>
          <Button
            size="lg"
            bg={theme.color.primary}
            color={theme.color.tertiary}
            transition="ease-in-out 0.2s"
            _hover={{ bg: `${theme.color.secondary}` }}
          >
            Cadastrar
          </Button>
        </Flex>
        <Flex direction="column">
          <Text fontSize="0.875rem" color={theme.color.quaternary}>
            Já tem uma conta?
            <LinkChakra
              href="/login"
              color={theme.color.primary}
              fontWeight="700"
              ml="1"
            >
              Faça seu login
            </LinkChakra>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
