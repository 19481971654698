import { useEffect, useState } from "react"
import { Link, Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
  Button,
  Flex,
  FormControl,
  Input,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react"
import { GrView } from "react-icons/gr"
import {
  SearchFilter,
  SearchParams,
  SearchResultInfo,
} from "../../../../entities"
import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import DataTable from "../../../../components/dataTable/DataTable"
import { VscBellDot } from "react-icons/vsc"
import { BsPlusLg } from "react-icons/bs"
import { Notification } from "../../../../entities/csg/Notification"
import { NotificationService } from "../../../../services/csg/NotificationService"
import Loader from "../../../../components/Loader/Loader"
import theme from "../../../../config/Themes"

export default function NotificationListPage() {
  const navigate = useNavigate()

  const [notifications, setNotifications] = useState<Notification[]>([])
  const [resultInfo, setResultInfo] = useState(
    new SearchResultInfo({ pageSize: 20 })
  )
  const [name, setName] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [])

  const getFilters = (): SearchFilter[] => {
    const filters: SearchFilter[] = []

    if (name) {
      filters.push({ key: "name", value: name, operator: "like" })
    }

    return filters
  }

  const load = async (page = 1) => {
    const filters = getFilters()
    const searchParams = new SearchParams(filters, page, 10)

    setLoading(true)
    NotificationService.search(searchParams)
      .then((result) => {
        setNotifications(result.data)
        setResultInfo(result.info)
      })
      .finally(() => setLoading(false))
  }

  const renderActions = (params: any) => {
    return (
      <Flex gap="1rem" alignItems="center">
        <ChakraLink as={ReactRouterLink} to={`/notification/${params.id}`}>
          <Button
            bg="#E7ECFF"
            color={theme.color.primary}
            transition="0.2s"
            _hover={{
              bg: `${theme.color.secondary}`,
              color: `${theme.color.tertiary}`,
            }}
          >
            <GrView />
          </Button>
        </ChakraLink>
      </Flex>
    )
  }

  const columns = [
    {
      field: "title",
      headerName: "Nome da Notificação",
      mWidth: "55%",
    },
    {
      headerName: "Ações",
      renderCell: renderActions,
      mWidth: "65%",
      mAlign: "end",
      mDisplay: "none",
    },
  ]

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Notificações", link: "/notification" },
            ]}
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex
              fontSize="2rem"
              fontWeight="700"
              color={theme.color.secondary}
            >
              <VscBellDot />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color={theme.color.secondary}
              fontFamily="DM Sans"
            >
              Notificações
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="auto"
          ml="auto"
          alignSelf="flex-end"
          justifyContent={{
            base: "flex-end",
            sm: "flex-end",
            md: "flex-end",
            lg: "flex-end",
          }}
          gap="1.25rem"
          alignItems="flex-end"
          direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
        >
          <FormControl
            w="100%"
            maxW={{ base: "100%", sm: "100%", md: "100%", lg: "648px" }}
            isInvalid={false}
            display="flex"
            justifyContent="flex-end"
            gap="0.5rem"
            flexWrap="wrap"
          >
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="name"
              placeholder="Nome da notificação"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <Flex gap="1rem">
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg={theme.color.primary}
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color={theme.color.tertiary}
              _hover={{ bg: `${theme.color.primary}` }}
              onClick={() => load(1)}
            >
              Buscar
            </Button>
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#F7F8F9"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#1A202C"
              _hover={{ bg: "#F7F8F9" }}
              onClick={() => navigate(`/notification/0`)}
              display="flex"
              gap="0.5rem"
              alignItems="center"
              justifyContent="center"
            >
              <BsPlusLg />
              Novo
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <DataTable
        checkboxSelection={false}
        rows={notifications}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />
    </Layout>
  )
}
