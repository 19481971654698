import { useState } from "react"
import { Flex, HStack, Hide } from "@chakra-ui/react"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import SidebarDropdown from "./sidebar/SidebarDropdown"

interface LayoutProps {
  children: any
  isHome?: boolean
}

export default function Layout(props: LayoutProps) {
  return (
    <HStack
      w="100%"
      h="auto"
      flexDirection="column"
      bg="rgba(231,236,255,0.300)"
    >
      <Header />
      <HStack w="100%" h="full" px="1.23rem" py="1.27rem" gap="1.72rem">
        <Hide breakpoint="(max-width: 930px)">
          <Flex
            as="aside"
            maxW="289px"
            h="full"
            alignSelf="start"
            alignItems="center"
            flexDirection="column"
            justifyContent="space-between"
            borderRadius="1rem"
            w="auto"
            className="comentar-os-4-abaixo e habilitar w=[auto]"
            // w="100%"
            // px="1.11rem"
            // py="1.51rem"
            // bg={theme.color.tertiary}
          >
            {/* <Sidebar
              collapse={collapse}
              setCollapse={setCollapse}
              menuItems={config.menuItems}
            /> */}
            <SidebarDropdown />
          </Flex>
        </Hide>
        <Flex
          as="main"
          w="100%"
          h="100%"
          minH="80vh"
          alignSelf="flex-start"
          flexDirection="column"
          alignItems="center"
          overflowX="hidden"
          //4 abaixo para o restante // sem os 4 abaixo para o Dashboard
          px={props.isHome !== true ? "1.11rem" : ""}
          py={props.isHome !== true ? "1.51rem" : ""}
          borderRadius={props.isHome !== true ? 8 : "none"}
          bg={props.isHome !== true ? "#fff" : ""}
        >
          {props.children}
        </Flex>
      </HStack>
      <Footer />
    </HStack>
  )
}
