import React, { ReactNode } from "react"
import { Link } from "react-router-dom"
import {
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react"
import theme from "../../../config/Themes"

interface BreadcrumbsItem {
  link: string
  label: string
}

interface TypeProps {
  items: BreadcrumbsItem[]
  additionalHeaderInfo?: ReactNode
}

export default function Breadcrumbs(props: TypeProps) {
  return (
    <Flex
      w="auto"
      minW="232px"
      justifyContent="space-between"
      direction={{ base: "column", sm: "row" }}
      gap={{ base: "1rem", sm: "0" }}
    >
      <Breadcrumb
        w="auto"
        mr="1rem"
        spacing="8px"
        separator="/"
        //separator={<ChevronRightIcon color={theme.color.quaternary} />}
        color={theme.color.senary}
        fontSize="0.75rem"
        fontWeight="400"
        fontFamily="DM Sans"
        display="flex"
        alignItems="center"
      >
        {props.items.map((item: BreadcrumbsItem, idx: number) => (
          <BreadcrumbItem key={`breadcrum-item-${idx}`}>
            <BreadcrumbLink
              color={theme.color.senary}
              fontSize="0.75rem"
              fontWeight="400"
              fontFamily="DM Sans"
              as={Link}
              to={item.link}
            >
              {item.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <Flex>{props.additionalHeaderInfo}</Flex>
    </Flex>
  )
}
