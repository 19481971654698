import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  Show,
  Hide,
  Flex,
  Button,
  Image,
  Link as ChakraLink,
} from "@chakra-ui/react"
import MobileMenu from "./mobileMenu/MobileMenu"
import {
  BsDoorOpenFill,
  BsFillQuestionCircleFill,
  BsPersonFill,
} from "react-icons/bs"
import UserStorage from "../../../util/userStorage"
import theme from "../../../config/Themes"

export default function Header() {
  return (
    <Flex
      w="100%"
      h="56px"
      p="1rem 1.25rem"
      bg={theme.color.tertiary}
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex alignItems="center" justifyContent="center">
        <ChakraLink as={ReactRouterLink} to={`/`}>
          <Image
            maxH="2.41rem"
            src={theme.logo.url}
            alt="Logotipo da Movvia"
            transition="0.2s"
            _hover={{ opacity: 0.8 }}
          />
        </ChakraLink>
      </Flex>
      <Hide breakpoint="(max-width: 415px)">
        <Flex alignItems="center" gap="1rem">
          <ChakraLink
            as={ReactRouterLink}
            to={`/user/${UserStorage.get()?.id}`}
          >
            <Button
              bg={theme.color.primary}
              p="0.6875rem"
              borderRadius="50%"
              _hover={{
                bg: `${theme.color.secondary}`,
                color: `${theme.color.tertiary}`,
              }}
              color={theme.color.tertiary}
              fontSize="1.25rem"
              transition="0.2s"
            >
              <BsPersonFill />
            </Button>
          </ChakraLink>
          <ChakraLink as={ReactRouterLink} to={`/help`}>
            <Button
              bg="#E2E8F0"
              p="0.6875rem"
              borderRadius="50%"
              _hover={{ bg: "#dadfe5", color: "#2D3748" }}
              color={theme.color.quaternary}
              fontSize="1.25rem"
              transition="0.2s"
            >
              <BsFillQuestionCircleFill />
            </Button>
          </ChakraLink>
          <ChakraLink
            as={ReactRouterLink}
            to={`/login`}
            onClick={() => UserStorage.logout()}
          >
            <Button
              bg="#E2E8F0"
              p="0.6875rem"
              borderRadius="50%"
              _hover={{ bg: "#dadfe5", color: "#2D3748" }}
              color={theme.color.quaternary}
              fontSize="1.25rem"
              transition="0.2s"
            >
              <BsDoorOpenFill />
            </Button>
          </ChakraLink>
        </Flex>
      </Hide>
      {/* <Hide breakpoint="(max-width: 415px)">
        <Flex gap={2}>
          <Menu>
            <MenuButton
              as={Button}
              color={theme.color.tertiary}
              bg={theme.color.quaternary}
              transition="ease-in-out 0.2s"
              _hover={{
                bg: `${theme.color.tertiary}`,
                color: `${theme.color.quaternary}`,
              }}
              _active={{
                bg: `${theme.color.secondary}`,
                color: `${theme.color.tertiary}`,
              }}
            >
              <WrapItem alignItems="center" gap={2}>
                <Avatar size="sm">
                  <Image
                    borderRadius="50%"
                    objectFit="contain"
                    src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"
                    alt="Profile"
                  />
                </Avatar>
                Atalhos
              </WrapItem>
            </MenuButton>
            <MenuList>
              <MenuGroup title="Painel">
                <MenuItem>
                  <RouterLink to="/">Painel</RouterLink>
                </MenuItem>
                <MenuItem>
                  <RouterLink to="/customer">Clientes</RouterLink>
                </MenuItem>
                <MenuItem>
                  <RouterLink to="/charge">Cobranças</RouterLink>
                </MenuItem>
                <MenuItem>
                  <RouterLink to="/vehicle/transfer">Transferências</RouterLink>
                </MenuItem>
                <MenuItem>
                  <RouterLink to="/balance-return">Resgate de Saldo</RouterLink>
                </MenuItem>
                <MenuItem>
                  <RouterLink to="/user">Usuários</RouterLink>
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup title="Ajuda">
                <MenuItem>
                  <ChakraLink
                    href="https://commotion.page/view/sFv4q5uqO1bNKBWXUNP0"
                    target="_blank"
                  >
                    Ajuda
                  </ChakraLink>
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
          <Box>
            <Button
              color={theme.color.quaternary}
              bg={theme.color.tertiary}
              transition="ease-in-out 0.2s"
              _hover={{
                bg: `${theme.color.secondary}`,
                color: `${theme.color.tertiary}`,
              }}
              onClick={() => {
                UserStorage.logout()
                window.location.href = "/login"
              }}
            >
              Sair
            </Button>
          </Box>
        </Flex>
      </Hide>
       */}
      <Show breakpoint="(max-width: 415px)">
        <Flex alignItems="center">
          <MobileMenu />
        </Flex>
      </Show>
    </Flex>
  )
}
